// var url = 'ws://192.168.50.43:2233'
var url = process.env.VUE_APP_WS || 'wss://admin.guocode.com/ws/'
var ws;
var lockReconnect = false;
var tt;
var conn_id = '';
var theMessage = '';

var websocket = {
    Init: function () {
        var self = this;
        if ("WebSocket" in window) {
            ws = new WebSocket(url);
        } else {
            console.log("您的浏览器不支持 WebSocket!");
            return;
        }
        ws.onmessage = (e) => {
            console.log("接收消息:" + e.data);
            self.heartCheck.start();
            let data = JSON.parse(e.data);
            theMessage = e.data;
            conn_id = data.action === "sync" ? data.conn_id : '';
        };

        ws.onclose = function () {
            console.log("连接已关闭")
            self.reconnect();
        }

        ws.onopen = function () {
            console.log("连接成功")
            self.heartCheck.start();
        }

        ws.onerror = function () {
            console.log("数据传输发生错误");
            self.reconnect()
        }
    },
    Send: function (data) {
        ws.send(JSON.stringify(data));
    },
    getWebSocket() {
        return ws;
    },
    getConnId() {
        return conn_id;
    },
    getMessage() {
        return theMessage;
    },
    getStatus() {
        if (ws.readyState == 0) {
            return "未连接";
        } else if (ws.readyState == 1) {
            return "已连接";
        } else if (ws.readyState == 2) {
            return "连接正在关闭";
        } else if (ws.readyState == 3) {
            return "连接已关闭";
        }
    },
    heartCheck: {
        timeout: 1000 * 25,
        timeoutObj: null,
        serverTimeoutObj: null,
        start: function () {
            // console.log('开始心跳检测');
            // var self = this;
            // this.timeoutObj && clearTimeout(this.timeoutObj);
            // this.serverTimeoutObj && clearTimeout(this.serverTimeoutObj);
            // this.timeoutObj = setTimeout(function () {
            //     //这里发送一个心跳，后端收到后，返回一个心跳消息，
            //     //onmessage拿到返回的心跳就说明连接正常
            //     console.log('心跳检测...');
            //     ws.send("HeartBeat:");
            //     self.serverTimeoutObj = setTimeout(function () {
            //         console.log('当前状态：'.ws.readyState);
            //         if (ws.readyState != 1) {
            //             ws.close();
            //         }
            //         // createWebSocket();
            //     }, self.timeout);
            // }, this.timeout)
        }
    },
    reconnect: function () {
        var self = this;
        if (lockReconnect) {
            return;
        }
        lockReconnect = true;
        //没连接上会一直重连，设置延迟避免请求过多
        tt && clearTimeout(tt);
        tt = setTimeout(function () {
            console.log("执行断线重连...")
            self.Init();
            lockReconnect = false;
        }, 2000);
    }
}

export default websocket;




